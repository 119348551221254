import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/ArticleLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Update June 7, 2018 - Hall of Shame`}</h3>
    <p>{`This article is a Hall of Shamer`}{`™`}{` for offering little to no value from the
moment it was written.`}</p>
    <p>{`I'm trying to infer programmer intention by observing semantic changes as they
edit files. My first attempt was to parse the syntax trees to determine the
changes made by the programmer. I first used a generic diff implementation but
quickly realized I would need somethign more semantically aware in able to infer
any serious meaning about the changes intended by the programmer. I started
reading about change detection algorithms which meant looking up a lot of
mathematical symbols I'm not accustomed to.`}</p>
    <p>{`As seems to always be the case with "great ideas that nobody has done" I've run
into a lot of unforseen issues. For example:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` name `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Merrick"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Say a programmer changes this variable name:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` me `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Merrick"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`The code assistant should note the change as occurs, character by character. As
we diff the two trees we might see the following events:`}</p>
    <p>{`The cursor jumps down to the end of name, and hits backspace:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` node_type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Identifier'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'change'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'nam'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`And another:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` node_type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Identifier'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'change'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'na'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`And another:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` node_type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Identifier'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'change'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'n'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`And one more:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` node_type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Identifier'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'change'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`''`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`But wait, we can't have an empty identifier, that won't parse... So, we need to
wait till we are parseable again, the developer types "m"`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` node_type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Identifier'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'change'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'m'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`And one last event:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` node_type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Identifier'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'change'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'me'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Ok, now the code assistant should suggest that you update the use of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`name`}</code>{`
found in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`console.log`}</code>{`, this poses a really challenging issue. Connecting "me"
to "name". The variable was "name" a long time ago, so how do we know to suggest
name to me at this point? Do we need to persist the scope some place? That we
can adjust references as we receive changes? So `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`name`}</code>{` references are updated to
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`nam`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`na`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`n`}</code>{`, (parse failure), `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`m`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`me`}</code>{`. And after even `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`identifier`}</code>{` change
events we suggest updating? How do we know "name" is the good state for
reference? How do we avoid pointing variables inbetween name to me, meaning if
there were also a variable "nam" how do we avoid accidentally pointing nam to
me? I suppose by checking if there is a corresponding VariableDeclarator for nam
I could avoid destructive suggestions.`}</p>
    <p>{`Conclusion for the day: My mind is tired. I anticipated this would be extremely
difficult but in my initial excitment I definitely believed it would be easier
than this. AI would be a long term goal, I'm just trying to solve the problem of
determining programmer intention using stateful change observation at this
point. I've been battling a lot shame and self-confidence issues as I've faced
friction. Hard not to feel stupid, or that I should have gone to school. I can't
help but feel inadequate as it takes me 2 hours to read 6 pages to try and
comprehend it and look up mathematical symbols on wikipedia.`}</p>
    <h2>{`Resources Used`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/List_of_mathematical_symbols"
        }}>{`List of Mathematical Symbols`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://ilpubs.stanford.edu:8090/115/1/1995-46.pdf"
        }}>{`Change Detection in Hierarchically Structured Information`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://hal.archives-ouvertes.fr/hal-01054552/document"
        }}>{`Fine-grained and Accurate Source Code Differencing`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      